<template>
  <div class="col-12 g">
    <br>
    <div class="card">
        <div class="card-header">
            <b-modal id="send" size="lg" title="ارسال رسائل لاولياء الامور" hide-footer>

                <div class="form-group">
                <h5>الرسالة</h5>
                <textarea class="form-control" v-model="message" @keyup="messageChanged()" @change="messageChanged()" rows="6" placeholder="اكتب الرسالة هنا..."></textarea>
                </div>
                <div class="col-12 g">
              <div class="card card-body border g">
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <table class="table table-hover table-bordered table-sm">
                            <tbody>
                                <tr>
                                <td>
                                    اسم الطالب
                                </td>
                                <td>
                                    {name}
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    الفصل
                                </td>
                                <td>
                                    {classroom}
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    الحضور
                                </td>
                                <td>
                                    {attend}
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    الغياب
                                </td>
                                <td>
                                    {ghiab}
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 col-lg-6">
                        <table class="table table-hover table-bordered table-sm">
                            <tbody>
                                <tr>
                                <td>
                                    الغياب بعذر
                                </td>
                                <td>
                                    {ghiab_ex}
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    التأخر
                                </td>
                                <td>
                                    {late}
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    التأخر بعذر
                                </td>
                                <td>
                                    {late_ex}
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center g">
                <button class="btn btn-primary" @click="send()">
                    <i class="fa fa-paper-plane"></i>
                    ارسال الآن
                </button>
            </div>
            </b-modal>
            <div>
                <div class="form-group">
                  <label for="">عرض فصل معين</label>
                  <select class="form-control" v-model="custom_classroom">
                    <option value="all">الكل</option>
                    <option v-for="c in classrooms" :key="c" :value="c">{{ c }}</option>
                  </select>
                </div>
            </div>
            <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="📥 طباعة / تصدير"
            variant="primary"
            >
              <b-dropdown-item @click="printDiv()">
                  <i class="fa fa-print"></i>
                  طباعة التقرير
              </b-dropdown-item><hr>
            <b-dropdown-item v-b-modal.send>
                  <i class="fa fa-paper-plane"></i>
                  ارسال لأولياء الامور
              </b-dropdown-item>
              <hr>
              <b-dropdown-item @click="exportToExcel()">
                  <i class="fa fa-download"></i>
                  تصدير التقرير اكسل
              </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm table-striped" id="headerTable">
                    <thead>
                        <th>
                            م
                        </th>
                        <th>
                            الطالب
                        </th>
                        <th>
                            الفصل
                        </th>
                        <th>
                         الحضور
                        </th>
                        <th>
                             الغياب
                        </th>
                        <th>
                             الغياب بعذر
                        </th>
                        <th>
                             التأخر
                        </th>
                        <th>
                             التأخر بعذر
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="(student, index) in students">
                            <tr :key="student.name" v-if="custom_classroom == 'all' || custom_classroom == student.classroom">
                                <td>
                                    {{ index+1 }}
                                </td>
                                <td>
                                    {{ student.name }}
                                </td>
                                <td>
                                    {{ student.classroom }}
                                </td>
                                <td>
                                    {{ student.attend }}
                                </td>
                                <td>
                                    {{ student.ghiab }}
                                </td>
                                <td>
                                    {{ student.ghiab_ex }}
                                </td>
                                <td>
                                    {{ student.late }}
                                </td>
                                <td>
                                    {{ student.late_ex }}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="modal-students-ghiab-manual" size="lg" title="ارسال رسائل لاولياء الامور الغائبين" hide-footer>
        <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody id="students-messages">
                    <template v-for="(student, c) in students">
                      <tr :key="c">
                          <td>{{ student.phone }}</td>
                          <td>{{ student.name }}</td>
                          <td><textarea class='form-control msg' readonly v-model="student.message"></textarea></td>
                      </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </b-modal>
    <button
      class="dnone"
      id="students-ghiab-manual"
      @click="sendModal()"
      v-b-modal.modal-students-ghiab-manual
    ></button>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
    BDropdown,
    BDropdownItem,
    },
    directives: {
    Ripple,
    "b-modal": VBModal,
    },
    data(){
        return {
            students: [],
            n_students: [],
            school_id: localStorage.getItem("school_id"),
            arr: [],
            custom_classroom: "all",
            classrooms: [],
            message: `الطالب {name}
الفصل: {classroom}

تقرير حضور وغياب الحصص في منصة مدرستي

الحضور: {attend}
الغياب: {ghiab}
الغياب بعذر: {ghiab_ex}
التأخر: {late}
التأخر بعذر: {late_ex}`,
            view_archive: false,
            selected: "",
            days: {
                0: "الاحد",
                1: "الاثنين",
                2: "الثلاثاء",
                3: "الاربعاء",
                4: "الخميس",
                5: "الجمعة",
                6: "السبت"
            },
        }
    },
    created(){
        var g = this;
        var naqar_get = function(t){
        return new Promise((resolveOuter) => {
            $("body").append(`<textarea class='get-local-storage' name='${t}'></textarea>`)
            var i111 = setInterval(function(){
                if($(".get-local-storage-value[name='"+t+"']").length){
                    clearInterval(i111)
                    resolveOuter($(".get-local-storage-value[name='"+t+"']").val())
                }
            })
        });
        }
        setTimeout(() => {
            naqar_get("full_lessons").then(function(data){
                g.students = JSON.parse(data).map(function(x){x.message = ""; return x;})
                g.messageChanged()
                })
        }, 1000)
    },methods: {

        refresh(){
          this.students = JSON.parse(JSON.stringify(this.students))
        },
        messageChanged(){
          var g = this;
          g.students = g.students.map(function(student){
            student.message = g.message.replace("{name}", student?.name)
            .replace("{classroom}", student?.classroom)
            .replace("{attend}", student?.attend)
            .replace("{ghiab}", student?.ghiab)
            .replace("{ghiab_ex}", student?.ghiab_ex)
            .replace("{late}", student?.late)
            .replace("{late_ex}", student?.late_ex)
            return student;
          })
          console.log(g.students)
          g.refresh()
        },
        send(){
            $("#c-subscribe-ended").click()
            $("#after-send-settings-click").val("#students-ghiab-manual")
        },sendModal(){
          var g =this;
          if(localStorage.getItem('send')){
              g.selected = JSON.parse(localStorage.getItem('send')).selected
          }
          g.messageChanged()
        },
        sendNow(method){
            var g = this;
            var c = []
            g.students.forEach(function(s){
              if(s.show){
                c.push({
                  phone: s.phone,
                  message: s.message
                })
              }
            })
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: g.school_id,
                arr: JSON.stringify(c),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'students'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
        printDiv() {
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                .dnone2{
                    display: none
                }
                button{
                    background: none;
                    border:none
                }
                </style>`);
            newWin.document.close();
            newWin.print();
        },
        exportToExcel() {
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").outerHTML +
                '</body> ' +
                '</html>'
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
        },
        editDistance: (s1, s2) => {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
        var lastValue = i;
        for (var j = 0; j <= s2.length; j++) {
            if (i == 0)
            costs[j] = j;
            else {
            if (j > 0) {
                var newValue = costs[j - 1];
                if (s1.charAt(i - 1) != s2.charAt(j - 1))
                newValue = Math.min(Math.min(newValue, lastValue),
                    costs[j]) + 1;
                costs[j - 1] = lastValue;
                lastValue = newValue;
            }
            }
        }
        if (i > 0)
            costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    },
        similarity: function(s1, s2){
            var longer = s1;
            var shorter = s2;
            if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
            }
            var longerLength = longer.length;
            if (longerLength == 0) {
            return 1.0;
            }
            return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
        },
        details(student){
            var g = this;
            var arr = []
            for (const [date, valuex] of Object.entries(student.days)) {
                for (const [day, value] of Object.entries(valuex)) {
                    arr.push({
                        date: date,
                        day: day,
                        lessons: value
                    })
                }
            }
            g.arr = arr
        }
    }
}
</script>

<style>

</style>