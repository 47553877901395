import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/teacher-weeklyplan/:s/:t',
      name: 'teacher-weeklyplan',
      component: () => import('@/views/teacher/teacher-weeklyplan.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/weeklyplan/:s/:t',
      name: 'weeklyplan',
      component: () => import('@/views/pages/parent-weeklyplan.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/weeklyplan-pdf/:s/:t',
      name: 'weeklyplan-pdf',
      component: () => import('@/views/pages/pdf.vue'),
      meta: {
        layout: 'full',
      },
    },
    //
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/subscribe',
      name: 'subscribe',
      component: () => import('@/views/subscribe/index.vue')
    },
    {
      path: '/archive',
      name: 'archive',
      component: () => import('@/views/messages/archive.vue')
    },
    {
      path: '/installed',
      name: 'installed',
      component: () => import('@/views/web/installed.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/updated',
      name: 'updated',
      component: () => import('@/views/web/updated.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/teachers-lessons',
      name: 'teachers-lessons',
      component: () => import('@/views/reports/teachers-lessons.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/teachers-week',
      name: 'teachers-week',
      component: () => import('@/views/reports/teachers-week.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/not-active',
      name: 'not-active',
      component: () => import('@/views/reports/not-active.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/teachers-weeklyplan',
      name: 'teachers-weeklyplan',
      component: () => import('@/views/reports/teachers-weeklyplan.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/teachers-stats',
      name: 'teachers-stats',
      component: () => import('@/views/reports/teachers-stats.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/students-ghiab-manual',
      name: 'students-ghiab-manual',
      component: () => import('@/views/reports/students-ghiab-manual.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/students-access',
      name: 'students-access',
      component: () => import('@/views/reports/students-access.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/students-alerts',
      name: 'students-alerts',
      component: () => import('@/views/reports/students-alerts.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/noor-ghiab',
      name: 'noor-ghiab',
      component: () => import('@/views/reports/noor-ghiab.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/students-certs',
      name: 'students-certs',
      component: () => import('@/views/reports/students-certs.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/homeworks',
      name: 'homeworks',
      component: () => import('@/views/reports/homeworks.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/full-lessons',
      name: 'full-lessons',
      component: () => import('@/views/reports/full-lessons.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/stats',
      name: 'stats',
      component: () => import('@/views/reports/stats.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/teachers-full',
      name: 'teachers-full',
      component: () => import('@/views/reports/teachers-full.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/ghiab',
      name: 'ghiab',
      component: () => import('@/views/reports/ghiab.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports-archive',
      name: 'reports-archive',
      component: () => import('@/views/reports/archived.vue')
    },
    {
      path: '/school_id_required',
      name: 'error-school_id_required',
      component: () => import('@/views/web/school_id_required.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pages/students-send',
      name: 'students-send',
      component: () => import('@/views/pages/students-send.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pages/teachers-send',
      name: 'teachers-send',
      component: () => import('@/views/pages/teachers-send.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/pages/teachers-late',
      name: 'teachers-late',
      component: () => import('@/views/pages/teachers-late.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/subscribe-done',
      name: 'subscribe-done',
      component: () => import('@/views/subscribe/done.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reports/kushof',
      name: 'reports/kushof',
      component: () => import('@/views/reports/kushof.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/pages/contact.vue')
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    // m
    {
      path: '/m/teachers-lessons',
      name: 'm-teachers-lessons',
      component: () => import('@/views/m/teachers-lessons.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/m/teachers-full',
      name: 'm-teachers-full',
      component: () => import('@/views/m/teachers-full.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/m/subscribe',
      name: 'm-subscribe',
      component: () => import('@/views/m/subscribe.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/installed-m',
      name: 'installed-m',
      component: () => import('@/views/web/installed-m.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/updated-m',
      name: 'updated-m',
      component: () => import('@/views/web/updated-m.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      name: 'error-4042',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
